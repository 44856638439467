<template lang="pug">
#app
	v-container(grid-list-md, fluid)
		v-layout(v-if="$root.user.permissao != 0", row, wrap)
			AccessLock
		v-layout(v-if="$root.user.permissao == 0",row, wrap)
			v-flex.md12
				v-card.elevation-1
					v-card-text
						span.title {{ auditado.nome }}
						p.body-1.font-weight-light {{ auditado.identificador }}

		v-layout(v-if="$root.user.permissao == 0", row, wrap).mt-3
			v-flex.md12
				v-card.elevation-1
					v-card-text
						form(@submit.prevent="salvar")
							v-layout(row, wrap)
								v-flex.md6.xs12
									v-autocomplete(label="Selecione o auditor responsável", :items="usuarios", :disabled="!usuarios.length", item-text="nome", item-value="id", color="gray", v-model="auditoria.usuario_id", :loading="loadingUsuarios", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
								v-flex.md6.xs12
									v-menu(v-model="menuDataInicio", :close-on-content-click="false", :nudge-right="40", lazy, transition="scale-transition", offset-y, full-width, min-width="290px")
										template(v-slot:activator="{ on }")
											v-text-field(:value="dataInicioFormatada", label="Data de Início", v-on="on", clearable, readonly, @click:clear="auditoria.data_inicio = null;" )
										v-date-picker(v-model="auditoria.data_inicio", @input="menuDataInicio = false")
							v-layout(row, wrap)
								v-flex().md6.xs12
									v-autocomplete(label="Selecione o questionário", :items="questionarios", :disabled="(auditoria.id ? true : false) || (!questionarios.length ? true : false)", item-text="nome", item-value="id", color="gray", v-model="auditoria.questionario_id", :loading="loadingQuestionarios", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
								v-flex.md6.xs12
									v-autocomplete(label="Selecione o status", :items="$root.allStatusAuditoria", :disabled="!$root.allStatusAuditoria.length", item-text="nome", item-value="id", color="gray", v-model="auditoria.status", :filter="customFilter", required, v-validate="'required'")
							v-layout(row, wrap)
								v-flex.xs12.text-xs-right
									v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
									
		v-layout(v-if="$root.user.permissao == 0",row, wrap)
			v-flex.md12
				v-card(v-if="list.length || questoesSelecionadas.length").elevation-1.mt-3
					v-card-text
						v-layout(row, wrap)
							v-flex.md12
								.title.pa-3 Questões
						
				v-card(v-if="list.length || questoesSelecionadas.length").elevation-1.mt-3
					v-card-text
						v-layout(v-if="auditoria && auditoria.id", row, wrap)
							v-flex.md12
								v-layout(row, wrap).mt-3
									v-flex.md6
										v-layout(row, wrap)
											v-flex.md6.pa-3
												span.subheading.font-weight-bold Disponíveis({{ list.length }})
											v-flex.md6.text-xs-right.pa-0
												v-btn(:disabled="!list.length", color="success", @click="selecionarTodas()").white--text Selecionar todas
												//- span {{ list }}
											v-flex(v-if="list && list.length", v-for='item in list', :key='item.id').md12
												v-layout(row, wrap)
													v-flex.md10.pa-3
														span.font-weight-bold {{ item.questao.texto }}
														p.caption.font-weight-light {{ item.questao.categoria_questao.texto }}
													v-flex.md2.text-xs-right
														v-tooltip(top)
															v-btn(slot="activator", flat, icon, fab, dark, color='success', @click="selecionarQuestao(item)")
																v-icon check
															span Selecionar
											v-divider
									
									v-flex.md6.pa-3
										v-layout(row, wrap).mb-3
											v-flex.md6
												span.subheading.font-weight-bold Selecionadas({{ questoesSelecionadas.length }})
											v-flex.md6.text-xs-right.pa-0
												v-btn(:disabled="!questoesSelecionadas.length", color="danger", @click="removerTodas()").white--text Remover todas
											v-flex(v-if="questoesSelecionadas && questoesSelecionadas.length", v-for='item in questoesSelecionadas', :key='item.id').md12
												v-layout(row, wrap)
													v-flex.md10.pa-3
														span.font-weight-bold  {{ item.questao.texto }}
															p.caption.font-weight-light  {{ item.questao.categoria_questao.texto }}
													v-flex.md2.text-xs-right
														v-tooltip(top)
															v-btn(slot="activator", flat, icon, fab, dark, color='danger', @click="removerQuestao(item)")
																v-icon close
															span Remover
													v-divider
                      
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
		AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    auditado: {},
    auditoria: {},
    questoesSelecionadas: [],
    questoes: [],
    menuDataInicio: false,
    usuarios: [],
    questionarios: [],
    loading: false,
    loadingUsuarios: false,
    loadingQuestionarios: false,
  }),
  computed: {
    dataInicioFormatada () {
      return this.auditoria.data_inicio ? this.$moment(this.auditoria.data_inicio).format('DD/MM/YYYY') : '';
    },
    list () {
			const vm = this;
      return _.differenceBy(vm.questoes, vm.questoesSelecionadas, 'questao_id');
    }
  },
  mounted () {
		const vm = this;
		if (vm.$route.params.id) {
			vm.getAuditado(vm.$route.params);
		}
		if (vm.$route.params.idAuditoria) {
      vm.getAuditoria(vm.$route.params);
    }
    vm.getUsuarios();
    vm.getQuestionarios();
  },
  methods: {
    async update (auditoria) {
      const vm = this;
      try {
        delete auditoria.UsuarioId;
        const response = await vm.$axios.put(`/auditoria/${auditoria.id}`, auditoria);
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Auditoria salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a auditoria. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async save (auditoria) {
      const vm = this;
      try {
        auditoria.auditado_id = vm.$route.params.id;
        const response = await vm.$axios.post(`/auditoria`, auditoria);
        // vm.$router.push({ name: 'AuditadoAuditoria', params: { id: vm.$route.params.id, idAuditoria: response.data.id } });
        window.location.href = `/auditado/detalhes/${vm.$route.params.id}/auditoria/cadastro/${response.data.id}`;
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Auditoria salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a auditoria. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar () {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.auditoria.id ? 'update' : 'save'](vm.auditoria);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getAuditado (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/auditado/${params.id}`);
		    vm.auditado = response.data || {};
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getAuditoria (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/auditoria/${params.idAuditoria}`, { params : { fields : "questionario,questoes_auditoria" }});
        response.data.data_inicio = this.$moment(response.data.data_inicio).format("YYYY-MM-DD");
				vm.auditoria = response.data || {};
				vm.questoes = response.data && response.data.questionario && response.data.questionario.questoes_questionario ? response.data.questionario.questoes_questionario : [];
				vm.questoesSelecionadas = response.data && response.data.questoes_auditoria ? response.data.questoes_auditoria : [];
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getUsuarios () {
      const vm = this;
      try {
        vm.loadingUsuarios = true;
        const response = await vm.$axios.get('/usuario', { params: { fields: "usuario_licencas", ativo : 1 } });
        vm.usuarios = response.data.rows;
        vm.loadingUsuarios = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getQuestionarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/questionario', { params : { fields: "tipo_questionario", ativo : 1 }});
        vm.questionarios = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async selecionarQuestao (obj) {
      const vm = this;
      try {
        
        let questaoAuditoria = {};
        questaoAuditoria.auditoria_id = parseInt(vm.$route.params.idAuditoria);
        questaoAuditoria.audit_id = parseInt(vm.$route.params.idAuditoria);
        questaoAuditoria.questao_id = obj.questao.id;
        const response = await vm.$axios.post(`/questao-auditoria`, questaoAuditoria);
        vm.questoesSelecionadas.push(obj);
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Questão selecionada com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao selecionar a questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async selecionarTodas () {
      const vm = this;
      try {
        let questoes = [];
        _.map(vm.questoes, obj => {
            questoes.push({
                questao_id : obj.questao.id,
                auditoria_id : parseInt(vm.$route.params.idAuditoria),
                audit_id : parseInt(vm.$route.params.idAuditoria),
            });
        });
        const response = await vm.$axios.post(`/questao-auditoria/many`, { questoes });
        let questoesAux = _.differenceBy(vm.questoes, vm.questoesSelecionadas, 'id');
        _.map(questoesAux, questao => {
          vm.questoesSelecionadas.push(questao);
        });
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Questões selecionadas com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao selecionar as questões. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
      
    },
    async removerTodas () {
      const vm = this;
      try {
        let auditoria_id = vm.$route.params.idAuditoria;
        const response = await vm.$axios.delete(`/questao-auditoria/auditoria/${auditoria_id}`);
        vm.questoesSelecionadas = [];
        window.getApp.$emit('APP_ALERT', { color: 'warning', text: 'Questões removidas com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao remover a questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async removerQuestao (questao) {
      const vm = this;
      try {
        const response = await vm.$axios.delete(`/questao-auditoria/${questao.id}`, questao);
        let index = vm.questoesSelecionadas.indexOf(questao);
        vm.questoesSelecionadas.splice(index, 1);
        window.getApp.$emit('APP_ALERT', { color: 'warning', text: 'Questão removida com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao remover a questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(item.nome);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }
  },
};
</script>

<style scoped>

</style>

